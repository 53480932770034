<template>
	<v-layout column fill-height justify-start overflow-x-hidden>
		<v-flex v-if="loading > 0" title bold justify-center text-xs-center align-center pa-5>
			<v-progress-circular class="mx-2" indeterminate></v-progress-circular>
		</v-flex>
		<v-flex v-else-if="totalTasks === 0" v-t="'tasks.no_task'" title bold justify-center text-xs-center align-center pa-5 />
		<v-flex v-else scroll-y overflow-x-hidden @click.self="resetSelection">
			<v-treeview
				:items="tasks"
				:active.sync="treeviewSelection"
				active-class="selectedElement"
				:open.sync="openedTasks"
				:open-all="expandAll"
				item-children="children"
				:multiple-active="false"
				activatable
				hoverable
				return-object
			>
				<template v-slot:label="{ item }">
					<TasksManagerTask :value="item" :minimized="minimized" class="pointer" />
				</template>
			</v-treeview>
		</v-flex>
		<v-flex shrink>
			<v-layout row justify-center align-center mx-2>
				<v-flex shrink>
					{{
						(totalTasks > 0
							? paginationOffset * currentPage - paginationOffset + 1 + ' - ' + Math.min(paginationOffset * currentPage, totalTasks) + ' / '
							: '') + $tc('tasks.number_of_tasks', totalTasks, { nb_tasks: totalTasks })
					}}
				</v-flex>
				<v-flex shrink>
					<v-pagination v-model="currentPage" total-visible="5" :disabled="loading > 0 || totalPages <= 1" :length="totalPages" />
				</v-flex>
				<v-flex shrink justify-center align-center>
					<v-layout row justify-center align-center ml-2>
						<v-select v-model="paginationOffset" :disabled="loading > 0" :items="paginationOffsets" />
						<span v-t="'tasks.pagination_offset'" class="ml-2" />
					</v-layout>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerTaskList',
	components: {
		TasksManagerTask: () => ({
			component: import('@/components/TasksManager/TasksManagerTask')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		minimized: {
			required: false,
			type: Boolean,
			default: null
		}
	},
	data: function () {
		return {
			loading: 0,
			paginationOffsets: [5, 10, 15, 25, 50],
			currentPage: 0,
			totalPages: 0,
			totalTasks: 0,
			openedTasks: [],
			adjustedInnerSelection: false,
			reflectedOutsideSelection: false
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
			accountingFirm: state => state.accountingFirm.selected,
			company: state => state.company.selected,
			tasks: state => state.tasks.list,
			filters: state => state.tasks.filters,
			sorting: state => state.tasks.sorting,
			selectedTask: state => state.tasks.selected
		}),
		treeviewSelection: {
			get: function () {
				return [this.selectedTask]
			},
			set: function (selection) {
				this.service.selectTask(selection && selection[0] ? selection[0] : null)
			}
		},
		paginationOffset: {
			get: function () {
				return this.$store.state.tasks.paginationOffset
			},
			set: function (selection) {
				this.service.setPaginationOffset(selection)
			}
		},
		expandAll: function () {
			let result = false
			if (this.filters && this.filters.search && this.filters.search.trim().length > 0) {
				result = true
			}
			return result
		}
	},
	watch: {
		accountingFirmId: {
			handler: function () {
				this.loadTasks(true)
			}
		},
		vendorId: {
			handler: function () {
				if (!this.isAccountant) {
					this.loadTasks(true)
				}
			},
		},
		selectedTask: {
			handler: function (newSelectedTask) {
				this.service.getTaskParents(newSelectedTask).forEach(openedTask => {
					if (!this.openedTasks.includes(openedTask)) {
						this.openedTasks.push(openedTask)
					}
				})
			}
		},
		currentPage: {
			handler: function (newValue, oldValue) {
				if (this.loading === 0 && oldValue && oldValue != 0 && newValue != oldValue) {
					this.loadTasks()
				}
			}
		},
		filters: {
			deep: true,
			handler: function () {
				this.refreshTasks()
			}
		},
		sorting: {
			deep: true,
			handler: function () {
				this.refreshTasks()
			}
		},
		paginationOffset: {
			handler: function () {
				this.refreshTasks()
			}
		}
	},
	mounted: function () {
			this.refreshTasks()
		
	},
	methods: {
		resetSelection: function () {
			this.treeviewSelection.clear()
			this.service.selectTask(null)
		},
		refreshTasks: function () {
			return this.loadTasks(true)
		},
		loadTasks: function (reset = false) {
			this.loading++
			if (reset) {
				this.currentPage = 0
				this.totalPages = 0
				this.totalTasks = 0
			}
			return this.service
				.loadTasksList(this.currentPage)
				.then(data => {
					if (data && data.pagination) {
						this.currentPage = data.pagination.currentPage
						this.totalPages = data.pagination.totalPages
						this.totalTasks = data.pagination.total
					}
				})
				.catch(() => {})
				.finally(() => {
					this.loading--
				})
		}
	}
}
</script>

<style>
.selectedElement {
	background-color: rgba(var(--v-primary-transparent), 0.25) !important;
}
</style>

<style scoped>
:deep(.v-treeview-node__root) {
	min-height: 56px;
	max-width: 100%;
}

:deep(.v-treeview-node__content) {
	max-width: calc(100% - 24px);
}

:deep(.v-treeview-node--leaf > .v-treeview-node__root > .v-treeview-node__content) {
	max-width: 100%;
}

:deep(.v-treeview-node__label) {
	max-width: 100%;
}
</style>
